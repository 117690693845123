import React, { useState } from "react"

export default function TextShowMore({ content, limit }) {
    const [showAll, showMoreSta] = useState(false);
    const showLess = () =>showMoreSta(false);
    const showMore = () => showMoreSta(true);
    const toShow = content.substring(0, limit) + (content.length >= limit ? "...":"");
    if (showAll) {
        // We show the extended text and a link to reduce it
        return <div>
            {content}
            <a href=""    onClick={(e)=> { e.preventDefault(); showLess()}} >Leer menos</a>
        </div>
    }

    return <div>
        {toShow}
        {content.length >= limit ? <a href="" onClick={(e)=> { e.preventDefault(); showMore()}}>Leer más</a>:""}
       
    </div>
}