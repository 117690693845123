import { firestore, functions } from "./appFirebase"
import { getUserById } from "./userAutentification"

const guardarReaccion100Dias = async (DocumentID, TipoReaccion, UserID) => {
    return await firestore.collection("Reaccion").add({
        Collection: "Promesas",
        DocumentID,
        Date: new Date(),
        TipoReaccion: TipoReaccion,
        UserID,
    }).then(function (docRef) {
        return {
            status: true, Data: {
                Collection: "Promesas",
                DocumentID,
                Date: new Date(),
                TipoReaccion: TipoReaccion,
                UserID,
                id: docRef.id
            }
        }
    }).catch(function (error) {
        const errorMessage = `Error al insertar reacción. ${error}`;
        return { status: false, errorMessage }
    });
}

const EliminarReaccion = (ID) => firestore.collection('Reaccion').doc(ID).delete().then(function () {
    return { status: true }
}).catch(function (error) {
    console.log(error)
    return { error, status: false }
});


const GetReaction100Dias = async (ID_Document) => {

    return await firestore.collection('Reaccion').where("Collection", "==", "Promesas").where("DocumentID", "==", ID_Document).orderBy('Date').get()
        .then(async (snapshot) => {

            const ListadoReactions = [];
            await Promise.all(snapshot.docs.map(async (doc) => {
                const data = doc.data()
                data.id = doc.id
                const dataUser = await getUserById(data.UserID);
                if (dataUser.status) {
                    data.user = dataUser
                }
                ListadoReactions.push({ ...data })
            }
            ));
            return { ListadoReactions, status: true }
        }).catch(function (error) {
            console.log(error)
            return { error, status: false }
        });


    /* const GetComententarios100Dias = async (ID_Document) => {
        var addMessage = functions.httpsCallable(`/ApiHttp/v1/comentarios/Promesas/${ID_Document}`,);
        return await addMessage({ Collection: "Promesas", DocumentID: "Promesa 38" }).then(function (result) {
            // Read result of the Cloud Function.
            var sanitizedMessage = result.data;
            return { status: true, sanitizedMessage }
        }).catch(function (error) {
            // Getting the Error details.
            var code = error.code;
            var message = error.message;
            var details = error.details;
            return { status: false, error: message }
            // ...
        });*/

}

const processReaction = (Data) => {

    var Collection = Data.Collection;
    var DocumentID = Data.DocumentID;
    var UserID = Data.UserID;

    let citiesRef = firestore.collection('Reaccion');

    let queryRef = citiesRef.where('Collection', '==', Collection)
        .where("DocumentID", '==', DocumentID)
        .where("UserID", '==', UserID)
        .where("DocumentID", '==', DocumentID);
        queryRef.get().then(snapshot => {
            if (snapshot.empty) {
                console.log('No matching documents.');
                return;
            }
            snapshot.forEach(async (doc) => {
                console.info(Data.id, "ID_Reaction ")
                console.info(doc.id, " doc.id ")

                if (Data.id !== doc.id) {
                    await firestore.collection('Reaccion').doc(doc.id).delete();
                }
            });

            return true;
        })
        .catch(error => {
            console.log(error)
        });
}

export { guardarReaccion100Dias, GetReaction100Dias, EliminarReaccion, processReaction }