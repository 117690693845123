import { firestore } from './appFirebase';
import { ToSeoUrl } from "../Utils/functiones"

const getPromesas = async () => {
    const snapshot = await firestore.collection('Promesas').orderBy('Valor').get();
    const ListadoPromesas = [];
    snapshot.docs.map((doc) => {
        const data = doc.data()
        data.id = doc.id
        data.TitleURL = ToSeoUrl(data.Title)
        ListadoPromesas.push({ ...data })
    }
    );
    return ListadoPromesas

};

const getEstadisticas = async () => {
    const snapshot = await firestore.collection('Estadistica');
    const _Popularidad = await snapshot.doc('Popularidad').get();
    const Popularidad = _Popularidad.data();

    const _Promesas = await snapshot.doc('Promesas').get();
    const Promesas = _Promesas.data();

    return { Popularidad, Promesas };
};

export { getEstadisticas, getPromesas }