import React, { useState } from 'react';
import PreCacheImg from 'react-precache-img';
import TileView from 'devextreme-react/tile-view';
import SharedInformation from '../../../components/modals/SharedInformation';
import '../../../access/wtf.css';
import { Popup } from 'devextreme-react/popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import {
  Link, useLocation
} from "react-router-dom";

function SListadoPromesas({ ListadoPromesa }) {
  const [popupVisible, setPopupVisible] = useState(false);
  const [textFilter, setTextFilter] = useState("");
  const [dataModal, setDataModal] = useState({});
  
  ListadoPromesa = ListadoPromesa.sort(function (vote1, vote2) {

    // Sort by votes
    // If the first item has a higher number, move it down
    // If the first item has a lower number, move it up
    if (vote1.Completado > vote2.Completado) return -1;
    if (vote1.Valor < vote2.Valor) return 1;

  });
  console.log(ListadoPromesa, "listado promesas")
  if(textFilter.length > 3)
    ListadoPromesa = ListadoPromesa.filter(x => x.Title.toLowerCase().includes(textFilter.toLowerCase()))

  const handleChange = (event) => {
    setTextFilter(event.target.value);
  }


  return (
    <div>
      <br></br>
      <div className="container ">
        <div class="md-form mt-0">
          <input class="form-control" type="text" onChange={handleChange} placeholder="Buscar en promesas" aria-label="buscar en promesas"></input>
        </div>
      </div>
      <TileView
        /*onItemClick={({ itemData }) => {
          setPopupVisible(true);
          setDataModal(itemData);
        }}*/
        style={{ alignItems: 'center' }}
        showScrollbar={true}

        direction="vertical"
        items={ListadoPromesa}
        height={600}
        baseItemHeight={240}
        baseItemWidth={240}
        itemMargin={10}
        itemComponent={TileViewItem}
      />
      {popupVisible ? (
        <Popup
          visible={popupVisible}
          onHiding={() => setPopupVisible(false)}
          dragEnabled={false}
          closeOnOutsideClick={true}
          showTitle={false}

          title={dataModal.Title}
        >
          {dataModal.Completado ? (
            <h4>
              <span>
                <FontAwesomeIcon color="#3689d6" icon={faThumbsUp} />{' '}
              </span>
              {' Promesa cumplida'}
            </h4>
          ) : (
              <h4>
                <span>
                  <FontAwesomeIcon color="#e55253" icon={faThumbsDown} />{' '}
                </span>
                {' Promesa por cumplir'}
              </h4>
            )}

          <SharedInformation
            id={"labels" + dataModal.Valor}
            link={window.location.href}
            title="Compartir"
            mensaje={`Monitor de las promesas a cumplir de Luis Abinader.
            Promesa #${dataModal.Valor} -  ${dataModal.Completado ? 'Promesa cumplida' : 'Promesa por cumplir'}
             `}
          />
          <br></br>
          <h4>{dataModal.Descripcion}</h4>

          <TileView
            dataSource={dataModal.Detalles}
            height={600}
            baseItemHeight={120}
            baseItemWidth={185}
            width="100%"
            itemMargin={10}
            itemRender={HomeImage}
          />
        </Popup>
      ) : (
          <></>
        )}
    </div>
  );
}
function HomeImage(data) {
  return (
    <a
      className="dx-tile-image"
      target="_blanck"
      href={data.url}
      style={{ backgroundImage: `url(${data.imagen})`, width: '100%', height: '100%' }}
    ></a>
  );
}

function TileViewItem({ data }) {
  let location = useLocation();
  const imageUrl = data.Completado ? data.URL_IMAGEN_CUMPLIDA : data.URL_IMAGEN_NO_CUMPLIDA;
  return (
    <Link
      key={1}
      to={{
        pathname: `/promesa/${data.TitleURL}`,
        // This is the trick! This link sets
        // the `background` in location state.
        state: { background: location }
      }}
    >
      <div className="dx-tile-content">
        {/* <div className="price">{data.Completado ? <img width="50" src={completado}></img> : <img width="50" src={ncompletado}></img>}</div> */}
        <div id={`imagen-${data.Valor}`} className="image" style={{ backgroundImage: `url(${imageUrl})` }}></div>
      </div>
      <PreCacheImg
      images={[
        imageUrl
      ]}
    />
    </Link>

  );
}

export default SListadoPromesas;
