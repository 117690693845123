const firebase = require('firebase/app');
const firebaseConfig = {
  apiKey: "AIzaSyBDnxmEG0zj9SctSBqjDxLacxTP0RADAeA",
  authDomain: "republica-dominicana-a3c28.firebaseapp.com",
  databaseURL: "https://republica-dominicana-a3c28.firebaseio.com",
  projectId: "republica-dominicana-a3c28",
  storageBucket: "republica-dominicana-a3c28.appspot.com",
  messagingSenderId: "341084627235",
  appId: "1:341084627235:web:0a9b97f1193b5b4bbd6c96",
  measurementId: "G-1M0LMEZE2T"
};
// Required for side-effects
require("firebase/firestore");
require("firebase/storage");
require("firebase/analytics");
require("firebase/auth");
require("firebase/database");
require("firebase/functions");



firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.analytics().logEvent('notification_received');
const functions = firebase.functions();

const storage = firebase.storage();
const firestore = firebase.firestore();

const auth = firebase.auth();

export {firebase ,firestore,storage,auth,functions}