import React from "react"
import '../../access/Popularity.css';
import Header from "../../components/Header/Header"
import SubHeader from "../../components/Header/subHeader"

import Footer from '../../components/footer';
export default ({ children }) => {

    return <div>
        <Header />
        <div className="container-fluid content-popularity">
            {children}
        </div>
    </div>;
}
