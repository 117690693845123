import React, { useState } from 'react'
import { Popup } from 'devextreme-react/popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import SharedInformation from '../modals/SharedInformation';
import TileView from 'devextreme-react/tile-view';
import DetallesPromesas from "./detallesPromesa"
import { ScrollView } from 'devextreme-react/scroll-view';
import {
    useParams,
    useHistory,
} from "react-router-dom";
function ModalPromesas({ ListadoPromesas }) {
    const [modal, showModal] = useState(true)
    let history = useHistory();
    let { id } = useParams();
    const dataModal = ListadoPromesas.find(x => x.TitleURL == id)
    if (!dataModal) return null;
    let back = e => {
        // e.stopPropagation();
        history.goBack();
    };
    var myRef = React.createRef();
    return <Popup
        visible={modal}
        fullScreen={true}
        // onHiding={() => setPopupVisible(false)}
        dragEnabled={false}
        onHidden={back}
        closeOnOutsideClick={true}
        showTitle={false}
        ref={myRef}
        title={dataModal.Title}
    >
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li  onClick={()=>showModal(false)} className="breadcrumb-item "><a href="" onClick={(e)=>e.preventDefault()}>Cerrar</a></li>
            </ol>
        </nav>
        <ScrollView>

            <DetallesPromesas ListadoPromesas={ListadoPromesas} Showheader={true} />
            <br></br>
            <br></br>
            <br></br>

        </ScrollView>
    </Popup>

}

function HomeImage(data) {
    return (
        <a
            className="dx-tile-image"
            target="_blanck"
            href={data.url}
            style={{ backgroundImage: `url(${data.imagen})`, width: '100%', height: '100%' }}
        ></a>
    );
}

export default ModalPromesas;