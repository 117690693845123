import '../../../access/Popularity.css';
import ChartFirst from '../../../components/Cabecera/CharFirst';
import React from 'react';
import SharedInformation from '../../../components/modals/SharedInformation';


function Popularity({ Popularidad, Promesas }) {
  const cumplidas =  ((Promesas.Realizado / 46) * 100).toFixed(2)
  const messageShared = `Monitor de las promesas a cumplir de Luis Abinader.
Cumplidas ${cumplidas}%
Mira la popularidad positiva/negativa de Luis Abinader.
 ${Popularidad.positivo}% Positiva / ${Popularidad.Negativo}% Negativa
 `;
  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-3 col-lg-2 d-flex justify-content-center ">
          <div className="photo  left ">
            <div className="wrapper  LuisAbinader"></div>
            <div className="text">Luis Abinader</div>
            <div className="text2">Presidente Rep. Dominicana</div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h2 style={{ fontWeight: "bold"  ,  color: "#020f81" }}>Monitor de promesas de campaña.</h2>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="row ">
                <div className="col">
                  <div className="float-right">
                    <img height="50" src="https://i.ibb.co/8cBytV0/100dias.png"
                    />
                  </div>

                </div>
              </div>
              <div className="row align-self-end left">
                <div className="col">

                  <h2 className="float-right" style={{ fontWeight: "bold" }}>
                    Día {Promesas.Dias}, restan {100 - Promesas.Dias} días.{' '}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">

            <div className="col">
              <div className="row">
                <div className="col-12">
                  <div className="row electoral">
                    {/* <div className="center">270</div> */}
                    <div className="col">
                      <div className="left blue">
                        <span className="description">Promesas cumplidas </span>
                        {/* <br />
                    <span className="number">{Popularidad.positivo}% Positiva</span> */}
                      </div>
                    </div>
                    {/* <div style={{ textAlign: 'center' }} className="col">
                  <div className=" blue">
                    <span className="description">{Popularidad.Perfiles} Usuarios Analizados</span>
                  <br />
                  <span style={{ textAlign: 'center' }} className="number">
                    
                  </span> 
                  </div>
                </div> */}
                    <div className="col">
                      <div className="right red">
                        <span className="description">{cumplidas}%</span>
                        {/*   <br /> 
                  <span className="number">{cumplidas}%</span> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <ChartFirst Popularidad={Popularidad} Promesas={Promesas} />
                </div>
                <div className="col-12">
                  <div className="row electoral">
                    {/* <div className="center">270</div> */}
                    <div className="col">
                      <div className="left blue">
                        <span className="description">Popularidad </span>
                        {/* <br />
                    <span className="number"> Cumplidas {cumplidas}%</span> */}
                      </div>
                    </div>
                    <div className="col">
                      <div className="right red">
                        <span className="description">{Popularidad.positivo}%</span>
                        {/* <br />
                    <span className="number">No cumplidas {((40 - Promesas.Realizado) / 40) * 100}%</span> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <ChartFirst /> */}
                {/* <div className="electoral">
            <div className="center">100%</div>
            <div className="left">
              <span className="number">100</span>
              <br />
              <span className="description">50,000</span>
              <span className="description">&nbsp;Perfiles</span>
            </div>
            <div className="right">
              <span className="number">100</span>
              <br />
              <span className="description">50,000</span>
              <span className="description">&nbsp;Perfiles</span>
            </div>
          </div> */}
              </div>
            </div>
            {/* <div className="col-12 col-sm-3 col-md-3 col-lg-2 d-flex justify-content-center">
          <div className="photo right">
            <div className="wrapper PRM"></div>
            <div className="text">Propuestas</div>
          </div>
        </div> */}
          </div>
        </div>
      </div>
      <div className="row  ">
        <div className="col">
          <div className="float-right">
            <SharedInformation
              title="Compartir"
              id="popularidad"
              link={window.location.href}
              mensaje={messageShared}
            />
          </div>

        </div>
      </div>
    </>
  );
}

export default Popularity;
