import React, { useState } from "react"
import { EliminarComentarios } from "../../../fire/comentarios"
import { fechaTimeStaToAgo } from "../../../Utils/functiones"
import TextShowMore from "../../textShowMore"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare, faTrash } from "@fortawesome/free-solid-svg-icons"
import SharedInformation from '../../modals/SharedInformation';

export default function TextComentario({ data, usuarioEstaLogeado }) {
    const dt = data.Date.toDate()
    const [eliminado, setEliminar] = useState(false)
    return eliminado == false ? <li className="left clearfix">
        <span className="chat-img float-xs-left d-flex align-items-center ">
            <img width="32" src={data.user.data.photoURL} alt={data.user.data.displayName} className="rounded-circle avatar w-48 gd-warning " />
        </span>
        <div className="chat-body clearfix">
            <div className="header">
                <strong className="primary-font"> {data.user.data.displayName}</strong> <small className="float-xs-right text-muted">
                    <span className="glyphicon glyphicon-time"></span>{fechaTimeStaToAgo(dt)}</small>
            </div>
            <TextShowMore content={data.Comentario} limit={60} ></TextShowMore>
        </div>
        <br></br>
        {usuarioEstaLogeado.status ?
            (usuarioEstaLogeado.status && usuarioEstaLogeado.user.uid == data.UserID) ?
                <button className="btn  btn-outline-secondary btn-sm" onClick={(e) => {
                    e.preventDefault()
                    if (window.confirm("¿Estás seguro de que quieres eliminar esto?")) {
                        EliminarComentarios(data.id)
                        setEliminar(true)
                    }

                }}><FontAwesomeIcon icon={faTrash} /></button> : <></> : <></>

        }&nbsp;&nbsp;<SharedInformation id={"labels" + data.UserID}
            link={window.location.href}
            texto=""
            nombreClase="btn  btn-outline-secondary btn-sm"
            title=" "
            mensaje={`Monitor de las promesas a cumplir de Luis Abinader.
${data.user.data.displayName}: ${data.Comentario}`}

        ></SharedInformation>
    </li> : <></>
}