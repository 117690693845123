import { auth, firebase, firestore } from './appFirebase';

const UserIsLogin = () => {
  var user = auth.currentUser;
  if (user) {
    return { user, status: true };
  } else {
    return { user, status: false };
  }
}
const CerrarSession = async () => firebase.auth().signOut().then(function () {
  return { status: true }
}).catch(function (error) {
  return { status: false, error }
});

const GoogleAutentificacion = async () => {
  var provider = new firebase.auth.GoogleAuthProvider();
  return await AutentificacionMetodo(provider);
}



const FacebookAutentificacion = async () => {
  var provider = new firebase.auth.FacebookAuthProvider();
  return await AutentificacionMetodo(provider);
}


const TwitterAutentificacion = async () => {
  var provider = new firebase.auth.TwitterAuthProvider();
  return await AutentificacionMetodo(provider);
}

const AutentificacionMetodo = async (provider) => auth.signInWithPopup(provider).then(function (result) {
  // This gives you a Google Access Token. You can use it to access the Google API.
  var token = result.credential.accessToken;
  // The signed-in user info.
  var user = result.user;
  console.log(user);
  return { user, token, token, status: true }
  // ...
}).catch(function (error) {
  // Handle Errors here.
  var errorCode = error.code;
  var errorMessage = error.message;
  // The email of the user's account used.
  var email = error.email;
  // The firebase.auth.AuthCredential type that was used.
  var credential = error.credential;
  firestore.collection("Errores").doc().set({
    errorCode, errorMessage, Tipo: "Error Login"
  })
  return { errorCode, errorMessage, email, credential, status: false }

  // ...
});

const getUserById = async (uid) => firestore.collection("Usuarios").doc(uid).get()
  .then(doc => {
    if (!doc.exists) {
      return { status: true, data: false }
    } else {
      return { status: true, data: doc.data() }
    }
  })
  .catch(error => {
    return { status: false, error }
  });

export { UserIsLogin, GoogleAutentificacion, FacebookAutentificacion,TwitterAutentificacion, CerrarSession, getUserById }
