import React from "react"
import {
    Link,
} from "react-router-dom";
function Header() {

    return <nav className="navbar headerr    sticky-top  flex-md-nowrap p-0 shadow navbar-light bg-light">
        <Link className="navbar-brand col-md-3 col-lg-2 mr-0 px-3" style={Styles.HeaderStyle} to="/">
            <img src="https://i.ibb.co/LJXPqG6/Logo-verdatos-2.png" width="40" height="40" className="d-inline-block align-top" alt="" loading="lazy"></img> Verdatos<spam style={Styles.HeaderTextStyle}>rd</spam>
        </Link>

        {/* <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-toggle="collapse" data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button> */}
        {/* <input className="form-control form-control-dark w-100" type="text" placeholder="Buscar en promesas" aria-label="Search" />
        <ul className="navbar-nav px-3">
            <li className="nav-item text-nowrap">
                <button class="btn btn-outline-success my-2 my-sm-0 " type="submit">Buscar</button>
            </li>
        </ul> */}
        
    </nav>

    return <header>
        <div className="header-gradient">
            <div className="row">

                <div className="col">
                    <Link to="/"> <img height="60" src="https://i.ibb.co/6vkCfSH/Logo-verdatos.png" ></img></Link>
                </div>
                <div className="col"></div>

            </div>
            {/* <div className="name">Election</div> */}
            {/* <Year /> */}
        </div>
    </header>

}
export default Header;

const Styles = {
    HeaderStyle: {
        fontSize: 27,
        fontWeight: 700,
        color: "#4f636c"
    }, HeaderTextStyle: { color: "#a6a7a8" }

}