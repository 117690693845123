import React from "react"
function Load() {
    return <div className="loader text-center">
        <div className="loader-inner">
            <div className="lds-roller mb-3">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>

            <h2 className="text-uppercase font-weight-bold">Cargando ...</h2>
            <div className="spinner-grow text-primary" role="status"></div>
            <p className="font-italic text-muted font-weight-bold">
                Los primeros <strong className="countdown text-dark font-weight-bold">100 </strong> días de{' '}
                <strong className="countdown text-dark font-weight-bold">Luis Abinader </strong>
            </p>
        </div>
    </div>
}

export default Load