import React from "react"
import '../../../access/Popularity.css';
import Popularity from '../popularidad/inicioPopularidad';
import SListadoPromesas from '../promesas/listadoPromesas';
function Home({ ListadoPromesas, Promesas, Popularidad }) {

    return <>
        <Popularity Popularidad={Popularidad} Promesas={Promesas} />
        <SListadoPromesas ListadoPromesa={ListadoPromesas} />
    </>

}

export default Home;
