import { firestore, functions } from "./appFirebase"
import { getUserById } from "./userAutentification"
const guardarComentario100Dias = async (DocumentID, Comentario, UserID) => {
    return await firestore.collection("Comentarios").add({
        Collection: "Promesas",
        DocumentID,
        Date: new Date(),
        Comentario: Comentario,
        UserID,
    }).then(function () {
        return { status: true }
    }).catch(function (error) {
        const errorMessage = `Error al insertar comentario. ${error}`;
        return { status: false, errorMessage }
    });

}
const guardarReaccion100Dias = async (DocumentID, TipoReaccion, UserID) => {
    return await firestore.collection("Reaccion").add({
        Collection: "Promesas",
        DocumentID,
        Date: new Date(),
        TipoReaccion: TipoReaccion,
        UserID,
    }).then(function () {
        return { status: true }
    }).catch(function (error) {
        const errorMessage = `Error al insertar reacción. ${error}`;
        return { status: false, errorMessage }
    });
}

const EliminarComentarios =  (ID)=> firestore.collection('Comentarios').doc(ID).delete();


const GetComententarios100Dias = async (ID_Document) => {
   /* await firestore.collection('Comentarios').where("Collection", "==", "Promesas").where("DocumentID", "==", ID_Document).orderBy('Date', 'desc')
        .onSnapshot(querySnapshot => {
            querySnapshot.docChanges().forEach(change => {
                if (change.type === 'added') {
                    console.log('New city: ', change.doc.data());
                }
                if (change.type === 'modified') {
                    console.log('Modified city: ', change.doc.data());
                }
                if (change.type === 'removed') {
                    console.log('Removed city: ', change.doc.data());
                }
            });
        });*/
    return await firestore.collection('Comentarios').where("Collection", "==", "Promesas").where("DocumentID", "==", ID_Document).orderBy('Date', 'desc').get()
        .then(async (snapshot) => {

            const ListadoComentarios = [];
            await Promise.all(snapshot.docs.map(async (doc) => {
                const data = doc.data()
                data.id = doc.id
                const dataUser = await getUserById(data.UserID);
                if (dataUser.status) {
                    data.user = dataUser
                }
                ListadoComentarios.push({ ...data })
            }
            ));
            return { ListadoComentarios, status: true }
        }).catch(function (error) {
            console.log(error)
            return { error, status: false }
        });


    /* const GetComententarios100Dias = async (ID_Document) => {
        var addMessage = functions.httpsCallable(`/ApiHttp/v1/comentarios/Promesas/${ID_Document}`,);
        return await addMessage({ Collection: "Promesas", DocumentID: "Promesa 38" }).then(function (result) {
            // Read result of the Cloud Function.
            var sanitizedMessage = result.data;
            return { status: true, sanitizedMessage }
        }).catch(function (error) {
            // Getting the Error details.
            var code = error.code;
            var message = error.message;
            var details = error.details;
            return { status: false, error: message }
            // ...
        });*/

}

export { guardarComentario100Dias, GetComententarios100Dias ,EliminarComentarios}