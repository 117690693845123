import React, { useState } from "react"
import "../../../access/chat.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown, faFlag } from '@fortawesome/free-solid-svg-icons';
import { extractHostname } from "../../../Utils/functiones"
import ComentarUsuario from "./comentarUsuario"
import TextComentario from "./textComentario"
import Reactions from "./reactions"

import { UserIsLogin, GoogleAutentificacion, FacebookAutentificacion, TwitterAutentificacion, CerrarSession } from "../../../fire/userAutentification"
import { guardarComentario100Dias } from "../../../fire/comentarios"
import { guardarReaccion100Dias } from "../../../fire/reactions"

import SharedInformation from '../../modals/SharedInformation';

export default function Comentarios({ dataModal,
    LoadlistadoComentarios,
    setLoadlistadoComentariosError,
    LoadlistadoReactions,
    handleReactionClick,
    error,
    setError,
    mensajeError,
    setMensajeError
}) {
    const [cargando, setCargando] = useState(false);

    const [comentario, setComentario] = useState("");
    const [comentarioButtonLoad, setComentarioButtonLoad] = useState(false);

    const usuarioEstaLogeado = UserIsLogin();


    const resultadosLogin = (result) => {
        setError(false);
        if (!result.status) {
            setError(true);
            setMensajeError(result.errorMessage)
        }
        setCargando(false);

    }


    const onClickGoogle = async (e) => {
        e.preventDefault()
        setCargando(true);
        const result = await GoogleAutentificacion()
        resultadosLogin(result)
    }

    const onClickFacebook = async (e) => {
        e.preventDefault()
        setCargando(true);
        const result = await FacebookAutentificacion()
        resultadosLogin(result)
    }

    const onClickTwitter = async (e) => {
        e.preventDefault()
        setCargando(true);
        const result = await TwitterAutentificacion()
        resultadosLogin(result)
    }


    const onClickCerrarSession = async (e) => {
        e.preventDefault()
        setCargando(true);
        await CerrarSession()
        setCargando(false);
    }

    const handleChange = (event) => {
        const Come = event.target.value;
        setComentario(Come)
    }

    const clickComentar = async () => {
        setComentarioButtonLoad(true);
        if (usuarioEstaLogeado.status) {
            setError(false);
            if (comentario) {
                const result = await guardarComentario100Dias(dataModal.id, comentario, usuarioEstaLogeado.user.uid)
                if (result.status) {
                    setComentario("")
                } else {
                    setError(true);
                    setMensajeError(result.errorMessage)
                }
            }
        } else {
            setError(true);
            setMensajeError("Necesitas seleccionar una red social para publicar un comentario.")

        }
        setComentarioButtonLoad(false);
    }



    const Variables = {
        onClickTwitter,
        handleChange, comentario, clickComentar, comentarioButtonLoad, cargando, onClickCerrarSession, onClickFacebook, onClickGoogle,
        error, mensajeError, setError, usuarioEstaLogeado
    }


    let ListComments = LoadlistadoComentarios.ListadoComentarios.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.Date) - new Date(a.Date);
    });

    return <>
        <div className="card card-primary">

            <div className="panel-collapse collapse show" id="collapseOne">
                <div className="card-body">
                    <h5 className="card-title">{dataModal.Title}</h5>
                    {

                        dataModal.Estado == "COMPLETADO" ? (
                            <>
                                <h6 className="card-subtitle mb-2 text-muted"><FontAwesomeIcon color="#28df99" icon={faFlag} /> Promesa cumplida</h6>
                            </>
                        ) : (
                                dataModal.Estado == "PROCESO" ?
                                    <h6 className="card-subtitle mb-2 text-muted">
                                        <FontAwesomeIcon color="#f3c623" icon={faThumbsUp} /> En proceso
                                </h6> : <h6 className="card-subtitle mb-2 text-muted">
                                        <FontAwesomeIcon color="#e55253" icon={faThumbsDown} /> Promesa por cumplir
                                </h6>
                            )
                    }
                    {/* <p className="card-text">...</p> */}
                    {dataModal.Detalles.map((x, index) => <a key={index} href={x.url} target="_blank" className="card-link">{x.descripcion || extractHostname(x.url)}</a>)}
                   <SharedInformation
                        id={"labels" + dataModal.Valor}
                        link={window.location.href}
                        title="Compartir"
                        mensaje={`Monitor de las promesas a cumplir de Luis Abinader.
            Promesa #${dataModal.Valor} -  ${dataModal.Completado ? 'Promesa cumplida' : 'Promesa por cumplir'}
             `}
                    /> 
                    {/* <a href="#" className="card-link">Listin diario</a> */}
                </div>
                <div class="dropdown-divider"></div>
                <div class="container">
                    <Reactions LoadlistadoReactions={LoadlistadoReactions} handleReactionClick={handleReactionClick} Variables={Variables} />
                </div>
                <div className="card-header card-footer">
                    <ComentarUsuario Variables={Variables} />
                </div>
                <div className="card-body h-100  container-fluid">
                    {
                        LoadlistadoComentarios.load ? <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <div className="loader-inner">
                                    <div className="spinner-grow text-primary" role="status"></div>
                                </div>
                            </div>
                        </div> : <></>
                    }
                    {LoadlistadoComentarios.Error ? <div className="alert alert-danger alert-dismissible fade show">
                        <strong>Error!</strong> {LoadlistadoComentarios.MensajeError}
                        <button type="button" onClick={() => setLoadlistadoComentariosError(false)} className="close" data-dismiss="alert">&times;</button>
                    </div> : <></>
                    }
                    <ul className="chat">
                        {ListComments.map((value, index) => <TextComentario key={index} data={value} usuarioEstaLogeado={usuarioEstaLogeado} />)}

                    </ul>


                </div>

            </div>
        </div>
    </>


}