import React, { useState, useEffect } from 'react'
import UrlNotMatch from "../urlNotMatch"
import Comentarios from "./Comentarios/index"

import {
    useParams,
    //  useHistory,
} from "react-router-dom";
import { firestore } from "../../fire/appFirebase"
import { getUserById, UserIsLogin } from "../../fire/userAutentification"
import { GetReaction100Dias, guardarReaccion100Dias, EliminarReaccion, processReaction } from "../../fire/reactions"
import { asyncForEach } from "../../Utils/functiones"

function DetallesPromesa({ ListadoPromesas, Showheader }) {
    const usuarioEstaLogeado = UserIsLogin();
    let { id } = useParams();
    const dataModal = ListadoPromesas.find(x => x.TitleURL == id)

    /* States */
    const [LoadlistadoComentarios, setLoadlistadoComentarios] = useState({ load: false, Error: false, MensajeError: "", ListadoComentarios: [] });
    const [LoadlistadoReactions, setLoadlistadoReactions] = useState({ load: false, Error: false, MensajeError: "", ListadoReactions: [] });
    const [error, setError] = useState(false);
    const [mensajeError, setMensajeError] = useState("");
    const EliminarReacion = (id) => {
        const ListadoReactions = LoadlistadoReactions.ListadoReactions.filter(x => x.id !== id)
        setLoadlistadoReactions({ ...LoadlistadoReactions, ListadoReactions: ListadoReactions, load: false, Error: false })
    }
    const GuardarReaccion = (Data) => {
        const ListadoReactions = LoadlistadoReactions.ListadoReactions.filter(x => x.UserID !== usuarioEstaLogeado.user.uid)
        ListadoReactions.unshift(Data)
        setLoadlistadoReactions({ ...LoadlistadoReactions, ListadoReactions: ListadoReactions, load: false, Error: false })
        processReaction(Data)
    }
    //End states

    const handleReactionClick = async (Data, Valor, accion) => {
        if (usuarioEstaLogeado.status) {
            setError(false);

            if (accion) {
                const result = await EliminarReaccion(Data.id)
                if (result.status == false) {
                    setError(true);
                    setMensajeError(result.errorMessage)
                } else {
                    EliminarReacion(Data.id)
                }
            } else {
                const result = await guardarReaccion100Dias(dataModal.id, Valor, usuarioEstaLogeado.user.uid)
                if (result.status) {
                    GuardarReaccion(result.Data)
                } else {
                    setError(true);
                    setMensajeError(result.errorMessage)
                }
            }

        } else {
            setError(true);
            setMensajeError("Necesitas seleccionar una red social para publicar un comentario.")

        }
    }

    const setLoadlistadoComentariosError = (value) => {
        setLoadlistadoComentarios({ ...LoadlistadoComentarios, Error: value })
    }


    useEffect(() => {
        async function CargarComentarios() {
            setLoadlistadoComentarios({ ...LoadlistadoComentarios, load: true })

            await firestore.collection('Comentarios').where("Collection", "==", "Promesas").where("DocumentID", "==", dataModal.id).orderBy('Date')
                .onSnapshot(async querySnapshot => {
                    const ListadoComentarios = LoadlistadoComentarios.ListadoComentarios

                    await asyncForEach(querySnapshot.docChanges(), async doc => {
                        if (doc.type === 'added') {
                            const data = doc.doc.data()
                            data.id = doc.doc.id
                            const dataUser = await getUserById(data.UserID);
                            if (dataUser.status) {
                                data.user = dataUser
                            }
                            ListadoComentarios.unshift(data)

                            // setLoadlistadoComentarios({ ...LoadlistadoComentarios, ListadoComentarios: ListadoComentarios, load: false, Error: false })
                        }

                    });

                    setLoadlistadoComentarios({ ...LoadlistadoComentarios, ListadoComentarios: ListadoComentarios, load: false, Error: false })
                });

        };


        async function CargarReacciones() {
            setLoadlistadoReactions({ ...LoadlistadoReactions, load: true })
            const ResultListadoReactions = await GetReaction100Dias(dataModal.id)

            if (ResultListadoReactions.status) {
                setLoadlistadoReactions({ ...LoadlistadoReactions, ListadoReactions: ResultListadoReactions.ListadoReactions, load: false, Error: false })
            }

            /* await firestore.collection('Reaccion').where("Collection", "==", "Promesas").where("DocumentID", "==", dataModal.id).orderBy('Date')
                 .onSnapshot(async querySnapshot => {
                             //Arreglar que tienes que crear un nuevo array de tipo object del dulicado listadoreactions
 
                     var ListadoReactions = LoadlistadoReactions.ListadoReactions
                     // const cantidad = LoadlistadoReactions.ListadoReactions.length
                     await asyncForEach(querySnapshot.docChanges(), async doc => {
                         console.log(doc.type, doc.doc.id);
                         if (doc.type === 'added') {
                             const data = doc.doc.data()
                             data.id = doc.doc.id
                             const dataUser = await getUserById(data.UserID);
                             if (dataUser.status) {
                                 data.user = dataUser
                             }
                             ListadoReactions.unshift(data)
 
                         }
 
                         if (doc.type === "removed") {
                             ListadoReactions = ListadoReactions.filter(x => x.id !== doc.doc.id)
                             console.log(ListadoReactions,"ListadoReactions")
 
                             //when is removed, apply here your function of callback;
                         }
                     });
                     setLoadlistadoReactions({ ...LoadlistadoReactions, ListadoReactions: ListadoReactions, load: false, Error: false })
                 });
                 */

        };
        CargarComentarios()
        CargarReacciones()
    }
        , []);





    // let history = useHistory();

    if (!dataModal) return <UrlNotMatch></UrlNotMatch>;
    window.document.title = dataModal.Title
    const imageUrl = dataModal.Completado ? dataModal.URL_IMAGEN_CUMPLIDA : dataModal.URL_IMAGEN_NO_CUMPLIDA;


    return <>
        <div style={{ paddingRight: 0, paddingLeft: 0 }} className="container-fluid">
            <div className="row" >
                <div style={{ backgroundColor: "rgb(0 0 0 / 2%)", paddingRight: 0, padding: 0 }} className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-center  ">
                    <div className="d-inline-flex ">
                        <img src={imageUrl} className="card-img" alt={dataModal.Title}></img>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                    <Comentarios
                        LoadlistadoReactions={LoadlistadoReactions}
                        LoadlistadoComentarios={LoadlistadoComentarios}
                        handleReactionClick={handleReactionClick}
                        setLoadlistadoReactions={setLoadlistadoReactions}
                        setLoadlistadoComentariosError={setLoadlistadoComentariosError}
                        error={error}
                        setError={setError}
                        mensajeError={mensajeError}
                        setError={setError}
                        dataModal={dataModal} />
                </div>
            </div>
        </div>
    </>
}



export default DetallesPromesa;