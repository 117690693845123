import React, { Component } from 'react';
import Main from '../containers/layouts/main'
import MainOnlyHeader from '../containers/layouts/mainOnlyHeader'



import ModalPromesas from "../components/Promesas/modalPromesa"
import DetallePromesa from "../components/Promesas/detallesPromesa"
import UrlNotMatch from "../components/urlNotMatch"

import TerminosCondiciones from '../components/terminosCondiciones';
import PoliticasPrivacidad from '../components/politicasPrivacidad';

import SListadoPromesas from '../containers/views/promesas/listadoPromesas';
import Home from '../containers/views/home/index';

import SPopularidad from '../containers/views/popularidad/index';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

function Index({ ListadoPromesas, Popularidad, Promesas }) {
  let location = useLocation();
  let background = location.state && location.state.background;
  return <>
    <Switch location={background || location}>
      <Route path="/promesa/:id" exact>
        <MainOnlyHeader>
          <Route path="/promesa/:id" exact>
            <DetallePromesa ListadoPromesas={ListadoPromesas} />
          </Route>
        </MainOnlyHeader>
      </Route>
      <Route>
        <Main>
          <Switch>
            <Route exact path="/">
              <Home ListadoPromesas={ListadoPromesas} Popularidad={Popularidad} Promesas={Promesas}></Home>
            </Route>
            <Route path="/promesas">
              <SListadoPromesas ListadoPromesa={ListadoPromesas} />
            </Route>
            <Route path="/popularidad">
              <SPopularidad Popularidad={Popularidad} Promesas={Promesas} />
            </Route>
            <Route path="/TerminosCondiciones">
              <TerminosCondiciones></TerminosCondiciones>
            </Route>
            <Route path="/politivasprivacidad">
              <PoliticasPrivacidad></PoliticasPrivacidad>
            </Route>
          </Switch>
        </Main>
      </Route>
      <Route path="*">
        <UrlNotMatch />
      </Route>
    </Switch>
    {background && <Route path="/promesa/:id" children={<ModalPromesas ListadoPromesas={ListadoPromesas} />} />}  </>
}

export default Index;
