import React, { Component } from "react"
import "../../../access/ListadoUsuarios.css"
function Reaccion({ listReactions }) {
   
    return (<div class="page-content page-container" id="page-content">
        <div class="padding">
            <div class="row">
                <div class="col">
                    <div class="list list-row block">
                        {listReactions.map((value, index) => value.user != undefined?
                            <div class="list-item" data-id="19">
                                <div><a href="#" data-abc="true"><span class="w-48 avatar gd-warning"><img src={value.user.data.photoURL}></img></span></a></div>
                                <div class="flex"> <a href="#" class="item-author text-color" data-abc="true">{value.user.data.displayName}</a>
                                    {/* <div class="item-except text-muted text-sm h-1x">For what reason would it be advisable for me to think about business content?</div> */}
                                </div>
                                <div class="no-wrap">
                                    <div class="item-date text-muted text-sm d-none d-md-block">13/12 18</div>
                                </div>
                            </div>:<></>)}

                    </div>
                </div>
            </div>
        </div>
    </div>)

}

export default Reaccion