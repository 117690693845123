import React, { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons"


export default function ComentarioUsuario(props) {
    const { handleChange, comentario, clickComentar, comentarioButtonLoad, cargando, onClickCerrarSession, onClickFacebook, onClickGoogle,
        error, mensajeError, setError, usuarioEstaLogeado, onClickTwitter } = props.Variables

    return <div className="row">
        <div>
            
        </div>

        <div className="col-12">


            <div className="input-group mb-3">
                {usuarioEstaLogeado.status ? <div style={{ height: "40px" }} className="input-group-prepend">
                    <img width="40" style={{ padding: 5 }} src={usuarioEstaLogeado.user.photoURL} alt={usuarioEstaLogeado.user.displayName} className=" avatar rounded-circle"></img>
                </div> : <></>}

                <textarea onChange={handleChange} value={comentario} aria-describedby="inputGroup-sizing-sm" style={{ height: "40px", fontSize: "14px", resize: "none" }} type="text" className="form-control" placeholder="Escribe un comentario ..." aria-label="Escribe un comentario ..." />
                <div style={{ height: "40px" }} className="input-group-append">
                    <button onClick={clickComentar} className="btn btn-outline-secondary" type="button" id="button-addon2">
                        {comentarioButtonLoad ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> : "Publicar"}
                    </button>
                </div>
               
            </div>
        </div>
        <div className="col-12">
            {
                cargando ?
                    <div style={{ paddingTop: 28 }} className="d-flex justify-content-center">
                        <div className="loader-inner">
                            <div className="spinner-grow text-primary" role="status"></div>
                        </div>
                    </div> :
                    <div className="d-flex flex-row-reverse align-items-start">
                        {usuarioEstaLogeado.status ?
                            <div key="face" className="p-2 bd-highlight">
                                <button href="" onClick={onClickCerrarSession} type="button" className="btn btn-outline-secondary">Cerrar sesión</button>
                            </div>
                            : <>

                                <div key="face" className="p-2 bd-highlight">
                                    <FontAwesomeIcon onClick={onClickFacebook} color="#3b5998" size="2x" icon={faFacebook} />
                                </div>
                                {/* <div key="gg" className="p-2 bd-highlight"> 
                       <FontAwesomeIcon onClick={() => alert()} color="#00acee" size="lg" icon={faTwitter} /></div> */}
                                <div key="tw" className="p-2 bd-highlight">
                                    <a href="" onClick={onClickGoogle}>
                                        <img src="https://img.icons8.com/color/33/000000/google-logo.png" />
                                    </a>
                                </div>
                                <div key="face" className="p-2 bd-highlight">
                                    <FontAwesomeIcon onClick={onClickTwitter} color="#00acee" size="2x" icon={faTwitter} />
                                </div>
                            </>}

                    </div>
            }
            {error ? <div className="alert alert-danger alert-dismissible fade show">
                <strong>Error!</strong> {mensajeError}
                <button type="button" onClick={() => setError(false)} class="close" data-dismiss="alert">&times;</button>
            </div> : <></>}

        </div>
        {/* <ModalError handleClose={handleClose} show={show} /> */}
    </div>
}



