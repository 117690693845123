import React from "react"


function Footer() {
    // return <footer style={{ textAlign: "center" }}  >
    //     <h5>Único propósito de mantener informado al pueblo dominicano. </h5>
    //     <br></br>
    //<h5>Creado por <a style={{ color: "#337ab7" }} href="https://twitter.com/DARIANVARGAS_A">Darian Vargas</a> & <a
    // style={{ color: "#337ab7" }} href="https://twitter.com/aferreras0">Alexander Ferreras</a></h5>
    // </footer>
    return <blockquote class="blockquote text-center ">

        <footer className="blockquote-footer">Único propósito de mantener informado al pueblo dominicano.</footer>
        <br></br>
        <p className="mb-0"> Creado por <a href="https://twitter.com/DARIANVARGAS_A">Darian Vargas</a> & <a
            href="https://twitter.com/aferreras0">Alexander Ferreras</a></p>
    </blockquote>
}

export default Footer