import React from 'react';
import { Chart, CommonSeriesSettings, CommonAxisSettings, Grid, Label, Legend,  Export } from 'devextreme-react/chart';

const series = [];

series[0] = {
  valueField: 'positivo',
  name: 'P',
  color: '#2b275b',
  // color: '#3689d6',
  border: {
    visible: true,
    color: '#fff',
    width: 1,
  },
  hoverMode: 'none',
};

series[3] = {
  valueField: 'negativo',
  color: '#e6e4e4',
  name: 'N',
  // color: '#e55253',
  border: {
    visible: true,
    color: '#fff',
    width: 1,
  },
  hoverMode: 'none',
};

function CharFirst({ Promesas, Popularidad }) {
  const dataSource = [
    { votesType: 'Popularidad',negativo: Popularidad.Negativo, positivo: Popularidad.positivo },
    { votesType:'Promesas cumplidad',   negativo: ((40 - Promesas.Realizado) / 40) * 100, positivo: (Promesas.Realizado / 40) * 100 },
  ];
  return (
    <Chart
      title="Gross State Product within the Great Lakes Region"
      className="big-chart"
      series={series}
      rotated={true}
      id="chart"
      customizePoint={customizePoint}
      dataSource={dataSource}
    >
      <CommonSeriesSettings argumentField="votesType" type="fullStackedBar"></CommonSeriesSettings>
      <CommonAxisSettings>
        <Grid visible={false} />
        <Label visible={false}  font={{size:15,weight:"bold"}}   />
      </CommonAxisSettings>
      <Legend visible={false} />
      <Export enabled={true} />
    </Chart>
  );
}

function customizePoint(arg) {
  if(arg.data.votesType == 'Promesas cumplidad' && arg.seriesName == "P"){
    return { color: '#c42531', hoverStyle: { color: '#c42531' } };
  }
  
 /* if (arg.value > this.state.highAverage) {
    return { color: '#ff7c7c', hoverStyle: { color: '#ff7c7c' } };
  } else if (arg.value < this.state.lowAverage) {
    return { color: '#8c8cff', hoverStyle: { color: '#8c8cff' } };
  }*/
}
export default CharFirst;
