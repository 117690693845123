import React from "react"
import Header from './Header/Header'
import "../access/urlNotFound.css"

function UrlNotMatch() {
    return <> 

        <div id="notfound">
            <div class="notfound">
                <div class="notfound-404"></div>
                <h1>404</h1>
                <h2>¡Ups! Página no encontrada</h2>
                <p>Lo sentimos, pero la página que está buscando no existe, se ha eliminado. nombre cambiado o no está disponible temporalmente</p>
                <a href="/">Volver a la página de inicio</a>
            </div>
        </div>
    </>

}

export default UrlNotMatch;