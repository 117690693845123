import React from "react"
import '../../access/Popularity.css';
import Header from "../../components/Header/Header"
import SubHeader from "../../components/Header/subHeader"

import Footer from '../../components/footer';
export default ({ children }) => {

    return <div>
        <Header />
        <div className="container-fuild content-popularity">
            <div className="row">
                <SubHeader />
                <div className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                    {children}
                </div>
            </div>
        </div>
        
        <Footer/>
    </div>;
}
