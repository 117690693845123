import React, { Component, useState } from "react"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Brand from "@fortawesome/free-solid-svg-icons"
import * as  Regular from "@fortawesome/free-regular-svg-icons"
import { Popup } from 'devextreme-react/popup';
import { ScrollView } from 'devextreme-react/scroll-view';
import ReaccionListUser from "../Reaction/usersReactions"
const ValidarSiExisteParaUsuario = (valor, IDUsuario) => {
    var data = valor.filter(x => x.UserID == IDUsuario)
    var exist = data.length > 0
    if (exist) {
        return { status: true, data: valor.find(x => x.UserID == IDUsuario) }
    }
    return { status: false, data: {} }
}

function Reactions({ Variables, LoadlistadoReactions, handleReactionClick }) {
    const [listReactions, setListReactions] = useState([])
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = (Data) => {
        setShow(true);
        setListReactions(Data);
    }
    

    const IDUsuario = Variables.usuarioEstaLogeado.status ? Variables.usuarioEstaLogeado.user.uid : 0

    const CUMPLIDA = LoadlistadoReactions.ListadoReactions.filter(x => x.TipoReaccion == "CUMPLIDA");
    const LIKE = LoadlistadoReactions.ListadoReactions.filter(x => x.TipoReaccion == "LIKE");
    const UNLIKE = LoadlistadoReactions.ListadoReactions.filter(x => x.TipoReaccion == "UNLIKE");

    const Usuario_CUMPLIDA = ValidarSiExisteParaUsuario(CUMPLIDA, IDUsuario)
    const Usuario_LIKE = ValidarSiExisteParaUsuario(LIKE, IDUsuario)
    const Usuario_Dislike = ValidarSiExisteParaUsuario(UNLIKE, IDUsuario)


    const IconoCumplida = Usuario_CUMPLIDA.status ? Brand.faFlag : Regular.faFlag
    const IconoLike = Usuario_LIKE.status ? Brand.faThumbsUp : Regular.faThumbsUp
    const IconoDislike = Usuario_Dislike.status ? Brand.faThumbsDown : Regular.faThumbsDown


    return (<div>

        {
            LoadlistadoReactions.load ? <div className="row">
                <div className="col-12 d-flex justify-content-center">
                    <div className="loader-inner">
                        <div className="spinner-grow text-primary" role="status"></div>
                    </div>
                </div>
            </div> : <div class="row reactions" >
                    <div class="col-4">
                        {CUMPLIDA.length > 0 ?
                            <a href="javascript:void(0)" onClick={()=>handleShow(CUMPLIDA)}><span ><strong>{CUMPLIDA.length}</strong></span> Cumplida </a> :
                            <p><span ><strong>{CUMPLIDA.length}</strong></span> Cumplida </p>}
                    </div>
                    <div class="col-4">
                        {LIKE.length > 0 ?
                            <a href="javascript:void(0)" onClick={()=>handleShow(LIKE)}><span ><strong>{LIKE.length}</strong></span> En proceso </a> :
                            <p><span ><strong>{LIKE.length}</strong></span> En proceso </p>}
                    </div>
                    <div class="col-4">
                        {UNLIKE.length > 0 ?
                            <a href="javascript:void(0)" onClick={()=>handleShow(UNLIKE)}><span ><strong>{UNLIKE.length}</strong></span> Por cumplir </a> :
                            <p><span ><strong>{UNLIKE.length}</strong></span> Por cumplir </p>}
                    </div>
                   
                </div>
        }

        <div class="dropdown-divider"></div>
        <div class="row reactions" >
            <div class="col-4">
                <a href="javascript:void(0)" onClick={() => handleReactionClick(Usuario_CUMPLIDA.data, "CUMPLIDA", Usuario_CUMPLIDA.status)}>   <p><span > <FontAwesomeIcon icon={IconoCumplida} size="lg" /> </span> Cumplida    </p></a>
            </div>
            <div class="col-4">
                <a href="javascript:void(0)" onClick={() => handleReactionClick(Usuario_LIKE.data, "LIKE", Usuario_LIKE.status)}>   <p><span > <FontAwesomeIcon icon={IconoLike} size="lg" /> </span> En proceso      </p></a>
            </div>
            <div class="col-4">
                <a href="javascript:void(0)" onClick={() => handleReactionClick(Usuario_Dislike.data, "UNLIKE", Usuario_Dislike.status)}>   <p><span > <FontAwesomeIcon icon={IconoDislike} size="lg" /> </span> Por cumplir      </p></a>
            </div>
        </div>
        <Popup
            visible={show}
            onHiding={handleClose}
            dragEnabled={false}
            closeOnOutsideClick={true}
            showTitle={false}
          maxWidth={500}
        >
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li onClick={handleClose} className="breadcrumb-item "><a href="" onClick={(e) => e.preventDefault()}>Cerrar</a></li>
                </ol>
            </nav>
            <ScrollView>
                <ReaccionListUser listReactions={listReactions} />
            </ScrollView>
        </Popup>
    </div>)
}

export default Reactions;