import React from 'react';

import { Twitter, Facebook, Whatsapp, Reddit, Linkedin, Mail, Telegram } from 'react-social-sharing';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';

function SharedInformation({ link, mensaje, title, id, nombreClase, texto }) {
  return (
    <>
      <button type="button" className={nombreClase || "btn mx-auto btn-outline-secondary btn-block"} data-toggle="modal" data-target={`#${id}`}>
        <span>
          <FontAwesomeIcon icon={faShare} />{' '}
        </span>
        {title}
      </button>
      <div className="modal fade" id={id} tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content col-12">
            <div className="modal-header">
              <h5 className="modal-title">{texto || "Compartir"}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-sm">
                    <Twitter
                      link={link}
                      message={mensaje}
                    //  label={serviceName => localise('share_label', { serviceName })}
                    />
                  </div>
                  <div className="col-sm">
                    <Facebook
                      link={link}
                      message={mensaje}
                    //  label={serviceName => localise('share_label', { serviceName })}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm">
                    <Whatsapp
                      link={link}
                      message={mensaje}
                    //  label={serviceName => localise('share_label', { serviceName })}
                    />
                  </div>

                </div>
                <div className="row">
                  <div className="col-sm">
                    <Telegram
                      link={link}
                      message={mensaje}
                    //  label={serviceName => localise('share_label', { serviceName })}
                    />
                  </div>
                  <div className="col-sm">
                    <Mail
                      link={link}
                      body={mensaje}
                    //  label={serviceName => localise('share_label', { serviceName })}
                    />
                  </div>
                </div>
                <div className="row">

                  <div className="col-sm">
                    <Linkedin
                      link={link}
                      message={mensaje}
                    //  label={serviceName => localise('share_label', { serviceName })}
                    />
                  </div>
                  <div className="col-sm">
                    <Reddit
                      link={link}
                    //  label={serviceName => localise('share_label', { serviceName })}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="modal-footer">
              <label style={{ fontWeight: 600 }}>
                URL <span className="message" />
              </label>
              <br />
              <div className="row">
                <input
                  className="col-10 ur"
                  type="url"
                  placeholder="https://www.arcardio.app/acodyseyy"
                  id="myInput"
                  aria-describedby="inputGroup-sizing-default"
                  style={{ height: '40px' }}
                />
                <button className="cpy" onclick="myFunction()">
                  <i className="far fa-clone" />
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default SharedInformation;
