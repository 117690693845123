import React, { useState, useEffect } from 'react';
import './access/App.css';
import { getPromesas, getEstadisticas } from "./fire/firestorePromesas"
import IndexPage from './router/Index';
import Load from "./components/Cabecera/Load"
import {
  BrowserRouter as Router,

} from "react-router-dom";


function App() {
  const [cargar, setLoad] = useState(false);
  const [ListadoPromesas, setListadoPromesas] = useState([]);
  const [Popularidad, setPopularidad] = useState({});
  const [Promesas, setPromesas] = useState({});



  useEffect(() =>{
    async function  Cargar(){
      const ListadoPromesas = await getPromesas();
      const { Popularidad, Promesas } = await getEstadisticas();
      setListadoPromesas(ListadoPromesas)
      setPopularidad(Popularidad)
      setPromesas(Promesas)
      setLoad(true)
    };
    Cargar()
  }
    , []);

  return cargar ? (
    <Router>
      <IndexPage ListadoPromesas={ListadoPromesas} Popularidad={Popularidad} Promesas={Promesas} />
    </Router>
  ) : (
      <Load />

    )
}

export default App;
